import React from "react";
import cityLogo from "../Images/logo.webp";
import cityLogo2 from "../Images/download.png";
import cityLogo3 from "../Images/AmericasSleepSpecialists-gray.ee4c121ee70ee25c66db.png";

function HeaderUser() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="register-logo">
            <img
              src={cityLogo}
              alt="city-logo.png"
              className="img-fluid"
              width="360"
              height="118"
            />
            <img
              src={cityLogo2}
              alt="city-logo.png"
              className="img-fluid"
              width="360"
              height="118"
            />
            <img
              src={cityLogo3}
              alt="city-logo.png"
              className="img-fluid"
              width="360"
              height="118"
            />
          </div>
        </div>
        <div className="fix-white"></div>
      </div>
    </div>
  );
}
export default HeaderUser;
