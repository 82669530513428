import { React, useRef, useState, useEffect } from "react";

import Footer2 from "./Footer2";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import UserformService from "../services/userform.service";

import Select from "react-select";
import HeaderUser from "./HeaderUser";

function RegisterForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Registration Form";
  const [fields, setFields] = useState({
    sales_name: "",
    store_location: "",
    emailid: "",
    name: "",
    lname: "",
    address: "",
    p_address: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    comfort_preference: "",
    technology_preference: "",
  });

  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);

  const [states, setStates] = useState([]);
  const [locations, setLocations] = useState([]);

  const [value_userform, setValue_userform] = useState([]);
  const [i_agree, setI_agree] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  let comfort = [
    { value: "Beautyrest Black", label: "Beautyrest Black" },
    { value: "Beautyrest Harmony", label: "Beautyrest Harmony" },
    { value: "Beautyrest Harmony Lux", label: "Beautyrest Harmony Lux" },
    { value: "BeautySleep", label: "BeautySleep" },
    {
      value: "Mattress First/ America’s Sleep",
      label: "Mattress First/ America’s Sleep",
    },
    { value: "Serta Classic", label: "Serta Classic" },
    { value: "Serta Icomfort", label: "Serta Icomfort" },
    { value: "Serta Perfect Sleeper", label: "Serta Perfect Sleeper" },
  ];

  let techPre = [
    { value: "Quilted", label: "Quilted" },
    { value: "Hybrid", label: "Hybrid" },
    { value: "Foam", label: "Foam" },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    async function getStates() {
      let errors = {};

      try {
        const response = await UserformService.getStates();

        var states = response.data.response;
        const results = [];
        states.map((value) => {
          return results.push({
            value: value.code,
            label: value.name,
          });
        });
        await setStates([...results]);

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }
    getStates();

    async function getLocations() {
      let errors = {};

      try {
        const response = await UserformService.getStoreLocation();

        var locations = response.data.response;
        const results = [];
        locations.map((value) => {
          return results.push({
            value: value.id,
            label: value.store_name,
          });
        });
        await setLocations([...results]);

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }
    getLocations();
  }, []);

  const ref = useRef();

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "state" && value === "true") {
      value = "";
    }
    if (name === "store_location" && value === "true") {
      value = "";
    }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();

    let data = {
      sales_name: fields?.sales_name,
      store_id: fields?.store_location,
      first_name: fields?.name,
      last_name: fields?.lname,
      email: fields?.emailid,
      address1: fields?.address,
      address2: fields?.p_address,
      city: fields?.city,
      state: fields?.state,
      zip: fields?.zipcode,
      phone: fields?.phone,
      comfort_preference: fields?.comfort_preference,
      technology_preference: fields?.technology_preference,
      i_agree: i_agree,
      disclaimer: disclaimer,
    };

    try {
      const responseuserform = UserformService.regData(data);
      responseuserform
        .then((response) => {
          var valueUserform = response?.data?.status;
          setValue_userform(valueUserform);

          Swal.fire({
            title: "Successful!",
            text: "Form Submitted!",
            type: "success",
            icon: "success",
          }).then((result) => {
            localStorage.removeItem("return_page");
            localStorage.setItem("isRegister", true);
            navigate("/register/registration-thankyou");
          });

          if (value_userform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          console.log(err);

          let errorData = {};
          errorData["sales_name"] =
            err?.response?.data &&
            err?.response?.data?.sales_name &&
            err?.response?.data?.sales_name[0];
          errorData["store_location"] =
            err?.response?.data &&
            err?.response?.data?.store_id &&
            err?.response?.data?.store_id[0];
          errorData["name"] =
            err?.response?.data &&
            err?.response?.data.first_name &&
            err?.response?.data.first_name[0];
          errorData["lname"] =
            err?.response?.data &&
            err?.response?.data.last_name &&
            err?.response?.data.last_name[0];
          errorData["emailid"] =
            err?.response?.data &&
            err?.response?.data?.email &&
            err?.response?.data?.email[0];
          errorData["address"] =
            err?.response?.data &&
            err?.response?.data.address1 &&
            err?.response?.data.address1[0];
          errorData["p_address"] =
            err?.response?.data &&
            err?.response?.data.address2 &&
            err?.response?.data.address2[0];
          errorData["city"] =
            err?.response?.data &&
            err?.response?.data?.city &&
            err?.response?.data?.city[0];
          errorData["state"] =
            err?.response?.data &&
            err?.response?.data?.state &&
            err?.response?.data?.state[0];
          errorData["zipcode"] =
            err?.response?.data &&
            err?.response?.data?.zip &&
            err?.response?.data?.zip[0];
          errorData["phone"] =
            err?.response?.data &&
            err?.response?.data?.phone &&
            err?.response?.data?.phone[0];
          errorData["comfort_preference"] =
            err?.response?.data &&
            err?.response?.data?.comfort_preference &&
            err?.response?.data?.comfort_preference[0];
          errorData["technology_preference"] =
            err?.response?.data &&
            err?.response?.data?.technology_preference &&
            err?.response?.data?.technology_preference[0];
          errorData["checkederrorcaptcha"] =
            err?.response?.data &&
            err?.response?.data?.captcha &&
            err?.response?.data?.captcha[0];
          errorData["checkederrortwo"] =
            err?.response?.data &&
            err?.response?.data?.size &&
            err?.response?.data?.size[0];
          errorData["disclaimer"] =
            err?.response?.data &&
            err?.response?.data?.disclaimer &&
            err?.response?.data?.disclaimer[0];
          errorData["agree"] =
            err?.response?.data &&
            err?.response?.data?.i_agree &&
            err?.response?.data?.i_agree[0];

          saveErrors(errorData);
          setTimeout(() => {
            ref?.current?.focus();
          }, 200);
        });
    } catch (err) {
      alert(err);
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      let errorData = {};
      errorData["sales_name"] = err?.response?.data?.sales_name[0];
      errorData["store_location"] = err?.response?.data?.store_id[0];
      errorData["name"] = err?.response?.data?.fname[0];
      errorData["lname"] = err?.response?.data?.lname[0];
      errorData["emailid"] = err?.response?.data?.phone[0];
      errorData["address"] = err?.response?.data?.address[0];
      errorData["p_address"] = err?.response?.data?.p_address[0];
      errorData["city"] = err?.response?.data?.city[0];
      errorData["state"] = err?.response?.data?.state[0];
      errorData["phone"] = err?.response?.data?.phone[0];
      errorData["zipcode"] = err?.response?.data?.zip[0];
      errorData["comfort_preference"] =
        err?.response?.data?.comfort_preference[0];
      errorData["technology_preference"] =
        err?.response?.data?.technology_preference[0];

      errorData["disclaimer"] = err?.response?.data?.disclaimer[0];
      errorData["agree"] = err?.response?.data?.i_agree[0];
      errorData["checkederrorcaptcha"] = err?.response?.data?.captcha[0];
      saveErrors(errorData);
    }
  };

  function onCaptchaChange(value) {
    if (value === null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  }

  const handleChangecheckbox = () => {
    setI_agree(!i_agree);
  };
  const handleChangecheckboxDisclaimer = () => {
    setDisclaimer(!disclaimer);
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <HeaderUser />
      <div className="container-fluid">
        <div id="main-registration-container">
          <div className="white-box form" id="font-style">
            <h1 className="text-center">
              Register your customer to receive their complimentary Stanley
              tumbler!
            </h1>
            <hr />
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
              // onClick={this.popup}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    Sales Associate Name <span className="red_point "></span>
                  </label>
                  <input
                    type="text"
                    placeholder="Sales Associate Name"
                    style={
                      errors.sales_name ? { border: "1px solid red" } : null
                    }
                    name="sales_name"
                    // tabIndex="1"
                    id="sales_name"
                    className="searchBox_deals vc-validate"
                    ref={errors.sales_name ? ref : null}
                    value={fields.sales_name || ""}
                    // autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.sales_name}</div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Retailer Name <span className="red_point">*</span>
                  </label>

                  <Select
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.store_location && !errors.sales_name
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select Retailer Name"
                    options={locations}
                    isSearchable={true}
                    isClearable={true}
                    name="store_location"
                    ref={
                      errors.store_location && !errors.sales_name ? ref : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["store_location"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["store_location"]: "",
                          };
                        });
                      }
                    }}
                  />

                  <div className="errorMsg">
                    {errors.store_location && !errors.sales_name
                      ? errors.store_location
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Customer First Name"
                    style={
                      errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="name"
                    // tabIndex="1"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? ref
                        : null
                    }
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.name && !errors.store_location && !errors.sales_name
                      ? errors.name
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Customer Last Name"
                    // tabIndex="1"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={
                      errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname &&
                    !errors.name &&
                    !errors.store_location &&
                    !errors.sales_name
                      ? errors.lname
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    // tabIndex="10"
                    ref={
                      errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="Customer Email"
                    value={fields.emailid || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.store_location &&
                    !errors.sales_name
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label required className="formsub">
                    Customer Address <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    style={
                      errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Customer Address"
                    // tabIndex="1"
                    id="address"
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.address || ""}
                    ref={
                      errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.store_location &&
                    !errors.sales_name
                      ? errors.address
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Apartment/Suite # (Optional)
                    <span className="red_point"></span>
                  </label>
                  <input
                    type="text"
                    name="p_address"
                    id="p_address"
                    placeholder="Apartment/Suite #"
                    // tabIndex="4"
                    autoFocus=""
                    value={fields.p_address || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    City <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="city"
                    style={
                      errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? { border: "1px solid red" }
                        : null
                    }
                    // tabIndex="4"
                    placeholder="City"
                    id="city"
                    value={fields.city || ""}
                    ref={
                      errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.store_location &&
                    !errors.sales_name
                      ? errors.city
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    State <span className="red_point">*</span>
                  </label>

                  <Select
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.emailid &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select State"
                    options={states}
                    isSearchable={true}
                    isClearable={true}
                    name="state"
                    ref={
                      errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.emailid &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? ref
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["state"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["state"]: "",
                          };
                        });
                      }
                    }}
                  />

                  <div className="errorMsg">
                    {errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.emailid &&
                    !errors.store_location &&
                    !errors.sales_name
                      ? errors.state
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Zip Code <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="zipcode"
                    style={
                      errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="zipcode"
                    maxLength="9"
                    // tabIndex="6"
                    placeholder="Zip Code"
                    value={fields.zipcode || ""}
                    ref={
                      errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {" "}
                    {errors.zipcode &&
                    !errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.store_location &&
                    !errors.sales_name
                      ? errors.zipcode
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone# <span className="red_point"></span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    style={
                      errors.phone &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="phone"
                    placeholder="Customer Phone"
                    value={fields.phone || ""}
                    ref={
                      errors.phone &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {" "}
                    {errors.phone &&
                    !errors.zipcode &&
                    !errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.store_location &&
                    !errors.sales_name
                      ? errors.phone
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Preferred SSB Mattress <span className="red_point">*</span>
                  </label>

                  <Select
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.comfort_preference &&
                      !errors.phone &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select Preferred SSB Mattress"
                    options={comfort}
                    isSearchable={true}
                    isClearable={true}
                    name="comfort_preference"
                    ref={
                      errors.comfort_preference &&
                      !errors.phone &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? ref
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["comfort_preference"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["comfort_preference"]: "",
                          };
                        });
                      }
                    }}
                  />

                  <div className="errorMsg">
                    {errors.comfort_preference &&
                    !errors.phone &&
                    !errors.zipcode &&
                    !errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.store_location &&
                    !errors.sales_name
                      ? errors.comfort_preference
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    SSB Mattress Model <span className="red_point "></span>
                  </label>
                  <input
                    type="text"
                    style={
                      errors.technology_preference &&
                      !errors.comfort_preference &&
                      !errors.phone &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="technology_preference"
                    placeholder="SSB Mattress Model"
                    value={fields.technology_preference || ""}
                    ref={
                      errors.technology_preference &&
                      !errors.comfort_preference &&
                      !errors.phone &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.store_location &&
                      !errors.sales_name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {" "}
                    {errors.technology_preference &&
                    !errors.comfort_preference &&
                    !errors.phone &&
                    !errors.zipcode &&
                    !errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.store_location &&
                    !errors.sales_name
                      ? errors.technology_preference
                      : null}
                  </div>
                </div>

                <div className="col-md-12 col-sm-12 mb-3">
                  <label class="agree label">
                    <input
                      type="checkbox"
                      id="myCheck"
                      name="checkbox"
                      class="conditions"
                      value={fields.checkbox}
                      onChange={handleChangecheckbox}
                    />
                    <span>
                      {" "}
                      By completing this form, I acknowledge that I give Serta
                      Simmons Bedding and participating retailers permission to
                      use my information for future promotional initiatives.
                    </span>
                  </label>
                  <br />
                  <div className="errorMsg">
                    {errors.agree &&
                    !errors.technology_preference &&
                    !errors.comfort_preference &&
                    !errors.phone &&
                    !errors.zipcode &&
                    !errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.store_location &&
                    !errors.disclaimer &&
                    !errors.sales_name
                      ? errors.agree
                      : null}
                  </div>
                </div>

                {/* <div className="overflow-hider">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                    onChange={onCaptchaChange}
                  />
                </div>
                <div className="col-md-6">
                  <div className="errorMsg">
                    {" "}
                    {errors.checkederrorcaptcha &&
                    !errors.agree &&
                    !errors.technology_preference &&
                    !errors.comfort_preference &&
                    !errors.phone &&
                    !errors.zipcode &&
                    !errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.store_location &&
                    !errors.disclaimer &&
                    !errors.sales_name
                      ? errors.checkederrorcaptcha
                      : null}
                  </div>
                </div> */}

                <div className="clearfix"></div>
              </div>

              <div className="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>

              <input
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn2 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default RegisterForm;
